import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";
import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
// import {load} from '@segmentik/sdk-client';
import {StyledLinkAsRouterLink} from "./components/styled/StyledLink";

function App() {
    const personalDetails = {
        name: "Pierre Janineh",
        location: "Tel Aviv, Israel",
        email: "me@pierrejanineh.com",
        phone: "+(972)-55-705-0133",
        brand:
            (
                <div>
                    {/*<p>Are you looking for a skilled and passionate Mobile Developer to bring your digital ideas to life? Look no further!</p>*/}
                    <p>Hi there! I'm Pierre, a Mobile Engineer with over 4 years of experience in Native development for
                        Android and iOS. I am incredibly enthusiastic about creating innovative, user-centric mobile
                        applications that leave a lasting impact on users worldwide.</p>

                    <h2>Who Am I?</h2>
                    <p>My journey in the world of mobile development began with a deep-rooted passion for technology and
                        a desire to craft exceptional user experiences. Throughout my career, I've taken great pride in
                        owning and developing successful apps such as EasyGate, Nikud Keyboard, Storage Analysis, and
                        Loccali, all available on the App Store.</p>

                    <h2>Photography Gallery</h2>
                    <p>In addition to my mobile development skills, I have a keen interest in photography. Feel free to
                        explore
                        my photography gallery, where I capture moments and scenes that inspire me. You can find a
                        collection
                        of my work that reflects my creative vision and storytelling through the lens.</p>

                    <div style={{textAlign: "center", paddingTop: "1.5em"}}>
                        <StyledLinkAsRouterLink to={"/gallery"}>Go to my gallery</StyledLinkAsRouterLink>
                    </div>

                    <h2>What Sets Me Apart?</h2>
                    <p>What truly sets me apart is my commitment to excellence in every project I take on. My
                        proficiency in Swift, Kotlin, and Java allows me to build clean, efficient, and
                        performance-driven code. With a keen eye for detail and a disciplined approach to development, I
                        ensure that my mobile applications are not only visually appealing but also scalable and
                        reliable.</p>

                    <h2>A Collaborative Partner</h2>
                    <p>As a dedicated team player, I believe that collaboration is the key to success. I thrive in
                        fast-paced, dynamic environments where I can work closely with cross-functional teams to bring
                        ideas to life. Whether I'm creating real-time mobile applications or integrating third-party
                        libraries and APIs, I'm driven by a desire to deliver seamless solutions that exceed
                        expectations.</p>

                    <h2>Passion for Innovation</h2>
                    <p>At heart, I'm an innovator. I enjoy tackling new challenges, staying at the forefront of mobile
                        technology, and embracing emerging trends. My deep curiosity and passion for innovation drive me
                        to continuously explore new possibilities, making me an ideal partner for your digital
                        transformation journey.</p>

                    <h2>How Can I Help You?</h2>
                    <p>Whether you're a startup with an exciting app idea or an established company seeking to enhance
                        your mobile presence, I'm here to support you. Let's collaborate to build cutting-edge mobile
                        apps, smart IoT solutions, and digital products that leave a lasting impact on your users.</p>
                    <br/>
                    <p>Feel free to explore my portfolio, including both mobile applications and my photography gallery,
                        and get in touch to discuss how I can contribute to your next project. Together, we can create
                        the
                        digital experiences that the world needs.</p>
                    <h3>Let's build something amazing together!</h3>
                </div>
            ),
    };

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };

    /*function initializeSegmentik() {
        const sg = load({
            apiKey: 'SKID8Y21DYHZ7UUD230Y'
        });
        sg.then(session => session.detect())
    }*/

    function initializeAnalytics() {
        const env = process.env.NODE_ENV || "development";
        if (env === 'production' && Object.values(firebaseConfig).every(Boolean)) {
            const app = initializeApp(firebaseConfig);
            return getAnalytics(app);
        }
        return null;
    }

    const analytics = initializeAnalytics();

    const location = useLocation();
    if (location.search.includes("?ref=")) {
        if (analytics) {
            let ref = location.search.split("ref=")[1];
            logEvent(analytics, 'reference', {name: ref});
        }
    }
    const [isSubdomain, setIsSubdomain] = useState(false);
    useEffect(() => {
        setIsSubdomain(window.location && window.location.hostname.split(".").length >= 3);
        // initializeSegmentik();
    }, []);

    return (
        <>
            {/*{!isSubdomain && */}<><Header/><AnimatedRoutes analytics={analytics}
                                                              personalDetails={personalDetails}/></>
            {/*}*/}
            {/*{isSubdomain && <><span style={{color: 'white'}}><a*/}
            {/*    href="http://example.com:3000/contact">Subdomain</a></span></>}*/}
        </>
    );
}

export default App;
