export default class ItunesService {
    async fetchiTunes( bundleId ) {
        return (await fetch(`https://itunes.apple.com/lookup?id=${encodeURIComponent(bundleId)}`)).json();
    }

    async getAppVersion(bundleId ) {
        const data = await this.fetchiTunes(bundleId);

        if (data.resultCount === 0) {
            return null;
        }

        return `v${data.results[0].version}`;
    }
}
