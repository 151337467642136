import projectData from "./projectsData.json";
import Project from "../../components/Project";
import PageHeader from "../../components/PageHeader";
import DocumentMeta from "react-document-meta";

const Portfolio = () => {
    const ProjectList = () =>
        projectData.map((project, i) => (
            <Project
                key={i}
                project={project}
            />
        ));

    const meta = {
        title: "Portfolio - Pierre Janineh | Projects and Apps Developed by Pierre Janineh",
        description: "Projects and Apps developed and created by Pierre with <3."
    };

    return (
        <DocumentMeta {...meta} >
            <section className="portfolio">
                <PageHeader title="Portfolio" /*description="View my work"*/ />
                <div className="row">
                    <ProjectList />
                </div>
            </section>
        </DocumentMeta>
    );
};

export default Portfolio;
